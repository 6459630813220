import React, { useState } from 'react';
import { motion } from 'framer-motion';
import RSSFeedComponent from './RSSFeedComponent'; // Import the RSSFeedComponent

const Blog = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  // Sample blog posts data with updated date field
  const blogPosts = [
    {
      title: "Frontend Frameworks",
      content: "React, Vue.js, and Angular remain popular choices for building interactive user interfaces...",
      link: "https://www.zenesys.com/angular-vs-react-vs-vue-js-which-framework-is-better-and-why",
      date: "March 14, 2024",
      image: "https://www.zenesys.com/getattachment/9d636ebe-b299-4e2e-8469-627f2f1ac267/Angular-vs-React-vs-Vuejs.png?lang=en-US&ext=.png" // Example image
    },
    {
      title: "Backend Technologies",
      content: "Node.js continues to dominate for JavaScript-based backends. However, technologies like Go and Rust are gaining traction for their performance benefits...",
      link: "https://www.netguru.com/blog/node-js-backend",
      date: "October 25, 2024",
      image: "https://www.netguru.com/hs-fs/hubfs/popular%20programming%20languages.jpg?width=4120&height=2336&name=popular%20programming%20languages.jpg" // Example image
    },
    {
      title: "Databases (NoSQL vs SQL)",
      content: "NoSQL databases like MongoDB are well-suited for many web apps. For relational data, PostgreSQL is a robust open-source option...",
      link: "https://www.mongodb.com/resources/compare/mongodb-postgresql",
      date: "October 20, 2024",
      image: "https://images.contentstack.io/v3/assets/blt7151619cb9560896/bltd0cdf28502bd1893/646e1fc49caadbd9af1341a1/kxanqquypgtg5vxc6-Query_API_Slalom_1.svg" // Example image
    },
    {
      title: "Optimizing Web App Performance",
      content: "A fast, responsive web app is crucial for user retention...",
      link: "https://pieces.app/blog/optimize-web-application-performance",
      date: "August 10, 2024",
      image: "https://framerusercontent.com/images/afLm5OlxU4W0JFj9MRkqhZf9Vo.jpeg?scale-down-to=1024" // Example image
    },
    {
      title: "APIs and Microservices",
      content: "RESTful APIs remain common, but GraphQL is increasingly popular for its flexibility. Microservices architectures allow for more scalable and maintainable apps...",
      link: "https://aws.amazon.com/compare/the-difference-between-graphql-and-rest/",
      date: "July 15, 2024",
      image: "https://d2908q01vomqb2.cloudfront.net/fc074d501302eb2b93e2554793fcaf50b3bf7291/2022/10/21/Fig1-how-graphql-works.png" // Example image
    },
    {
      title: "DevOps and Deployment",
      content: "Containerization with Docker and orchestration with Kubernetes have become standard. Serverless platforms like AWS Lambda can simplify deployment for certain use cases...",
      link: "https://lumigo.io/serverless-monitoring/serverless-and-kubernetes-key-differences-and-using-them-together/",
      date: "June 25, 2024",
      image: "https://b3716232.smushcdn.com/3716232/wp-content/uploads/2022/04/Serverless-monitoring-challenges.png?lossy=2&strip=1&webp=1" // Example image
    },
    {
      title: "Use HTTPS Everywhere",
      content: "Encrypt all data in transit by using HTTPS across your entire application...",
      link: "https://www.eff.org/https-everywhere",
      date: "June 25, 2024",
      image: "https://www.eff.org/files/banner_library/https-everywhere-logo.png" // Example image
    },
    {
      title: "Implement Proper Authentication",
      content: "Use strong password policies and multi-factor authentication. Consider OAuth 2.0 for third-party authentication...",
      link: "https://www.smscountry.com/blog/mfa-vs-oauth-comparison/",
      date: "June 19, 2024",
      image: "https://www.smscountry.com/blog/wp-content/uploads/2023/04/MFA-or-Oauth-Which-Authentication-Method-Should-You-Use-For-Your-Software.jpg" // Example image
    },
    {
      title: "Validate and Sanitize All Input",
      content: "Never trust user input. Validate on both client and server-side to prevent injection attacks...",
      link: "https://medium.com/@pythonshield/input-validation-and-sanitization-in-python-best-practices-for-secure-and-reliable-applications-a2c255b858e0",
      date: "June 01, 2022",
      image: "https://miro.medium.com/v2/resize:fit:720/format:webp/0*nfOgv9dLs6ClgD4U" // Example image
    },
    {
      title: "Keep Dependencies Updated",
      content: "Regularly update all libraries and frameworks to patch known vulnerabilities...",
      link: "https://medium.com/@shivam_bathla/a06-2021-vulnerable-and-outdated-components-a5d96017049c",
      date: "September 22, 2021",
      image: "https://miro.medium.com/v2/resize:fit:720/format:webp/0*LW7ptLTXqIs4pERx" // Example image
    },
    {
      title: "Employ the Principle of Least Privilege",
      content: "Give users and processes only the minimum level of access necessary...",
      link: "https://www.okta.com/identity-101/minimum-access-policy/#:~:text=The%20principle%20of%20least%20privilege,to%20complete%20their%20job%20responsibilities.",
      date: "August 26, 2024",
      image: "https://www.okta.com/sites/default/files/media/image/2021-02/WPR_Okta-User-Migration-Guide.png" // Example image
    },
    {
      title: "Conduct Regular Security Audits",
      content: "Perform penetration testing and code reviews to identify potential vulnerabilities...",
      link: "https://www.vertexcybersecurity.com.au/code-review-in-penetration-testing/#:~:text=Penetration%20testing%20stands%20as%20a,an%20organisation's%20cyber%20security%20measures.",
      date: "July 06, 2024",
      image: "https://cache.vertexcybersecurity.com/wp-content/uploads/2024/05/codereview-1536x768.jpg" // Example image
    },
    {
      title: "Minimize HTTP Requests",
      content: "Reduce the number of separate files your app needs to load...",
      link: "https://developer.android.com/topic/google-play-instant/guides/reduce-module-size",
      date: "March 25, 2024",
      image: "https://www.gstatic.com/devrel-devsite/prod/ve73583a8e360906cd2a16ff5571c1edda64ba053fc57f348efff9496af45f422/android/images/lockup.svg" // Example image
    },
    {
      title: "Optimize Images",
      content: "Compress images and use appropriate formats (e.g. WebP for better compression)...",
      link: "https://cloudinary.com/tools/compress-webp",
      date: "March 20, 2024",
      image: "https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_300,h_150/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1723547757/website_2021/Compress-WEBP.png?_i=AA" // Example image
    },
    {
      title: "Leverage Browser Caching",
      content: "Set appropriate cache headers to store static assets on the client...",
      link: "https://simonhearne.com/2022/caching-header-best-practices/",
      date: "Jan 28, 2022",
      image: "https://simonhearne.com/img/_dzUob7MLk-900.avif" // Example image
    },
    {
      title: "Use a Content Delivery Network (CDN)",
      content: "Distribute your content across multiple, geographically diverse servers...",
      link: "https://en.wikipedia.org/wiki/Content_delivery_network#:~:text=A%20content%20delivery%20network%20or,spatially%20relative%20to%20end%20users.",
      date: "June 20, 2024",
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/NCDN_-_CDN.svg/220px-NCDN_-_CDN.svg.png" // Example image
    },
    {
      title: "Implement Lazy Loading",
      content: "Only load resources as they're needed, especially for images and JavaScript...",
      link: "https://meenumatharu.medium.com/lazy-loading-images-in-javascript-boost-performance-and-user-experience-ecf6efe4f04d",
      date: "June 12, 2024",
      image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*9Wm-qIul31UoY4R1pS6TZA.png" // Example image
    },
    {
      title: "Optimize Your Database Queries",
      content: "Ensure your queries are efficient and use indexing appropriately...",
      link: "https://airbyte.com/data-engineering-resources/optimizing-mysql-queries",
      date: "July 24, 2023",
      image: "https://cdn.prod.website-files.com/6064b31ff49a2d31e0493af1/64be6fa691fa5f9abf7f6fb8_MySQL%20Queries-p-1080.webp" // Example image
    },
    // Add more blog posts as needed
  ];

  // Sort the blogPosts array by date to display recent post first
  const sortedPosts = [...blogPosts].sort((a, b) => new Date(b.date) - new Date(a.date));
  const recentPost = sortedPosts[0]; // Most recent post
  const rightSidePosts = sortedPosts.slice(1, 4); // Next three posts

  // Pagination logic for "All Blog Posts"
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col bg-transparent">
      {/* Title for Recent Blog Posts */}
      <h2 className="text-4xl font-bold text-center text-white mb-8">Recent Blog Post</h2>

      {/* Recent Blog Post Section */}
      <div className="flex flex-wrap mb-16">
        {/* Left side - featured post, smaller */}
        <div className="w-full md:w-1/2 p-4">
          <motion.div 
            className="p-6 backdrop-blur-md bg-white/10 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={recentPost.image} alt={recentPost.title} className="w-full h-60 object-cover mb-4 rounded-lg" />
            <p className="text-gray-500 text-sm mb-1">{recentPost.date}</p>
            <a href={recentPost.link} className="text-2xl font-semibold text-blue-400 hover:underline mb-2">
              {recentPost.title}
            </a>
            <p className="text-gray-300 mt-2 text-sm leading-relaxed">{recentPost.content}</p> {/* Smaller content */}
          </motion.div>
        </div>

        {/* Right side - larger recent posts */}
        <div className="w-full md:w-1/2 p-4 space-y-8">
          {rightSidePosts.map((post, index) => (
            <motion.div 
              key={index} 
              className="p-4 backdrop-blur-md bg-white/10 rounded-lg shadow-lg flex"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {/* Image on the left */}
              <img src={post.image} alt={post.title} className="w-1/3 h-24 object-cover rounded-lg mr-4" />
              
              {/* Date, Title, Content on the right */}
              <div className="w-2/3">
                <p className="text-gray-500 text-sm mb-1">{post.date}</p>
                <a href={post.link} className="text-lg font-semibold text-blue-400 hover:underline mb-2">{post.title}</a>
                <p className="text-gray-300 mt-1 text-sm">{post.content}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Title for All Blog Posts */}
      <h2 className="text-4xl font-bold text-center text-white mb-8">All Blog Posts</h2>

      {/* All Blog Posts with Pagination */}
      <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {currentPosts.map((post, index) => (
            <motion.div 
              key={index} 
              className="p-6 backdrop-blur-md bg-white/10 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover mb-4 rounded-lg" />
              <p className="text-gray-500 text-sm mb-1">{post.date}</p>
              <a href={post.link} className="text-lg font-semibold text-blue-400 hover:underline mb-2">{post.title}</a>
              <p className="text-gray-300 mt-2 text-sm leading-relaxed">{post.content}</p>
            </motion.div>
          ))}
        </div>

        {/* Pagination Buttons */}
        <div className="flex justify-center space-x-4 mt-8">
          <button 
            onClick={() => paginate(currentPage - 1)} 
            disabled={currentPage === 1} 
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400"
          >
            Previous
          </button>
          {[...Array(Math.ceil(blogPosts.length / postsPerPage)).keys()].map((page) => (
            <button 
              key={page + 1} 
              onClick={() => paginate(page + 1)} 
              className={`px-4 py-2 rounded-lg ${currentPage === page + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              {page + 1}
            </button>
          ))}
          <button 
            onClick={() => paginate(currentPage + 1)} 
            disabled={currentPage === Math.ceil(blogPosts.length / postsPerPage)} 
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blog;
