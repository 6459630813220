import React from "react";
import PrivacyPolicyModal from './PrivacyPolicyModal';  // Import the modal
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { FaInstagram, FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa'; // Import icons from react-icons

const Footer = () => {
  return (
    <footer className="bg-black-800 text-white py-8">
      <div className="container mx-auto text-center">
        <p className="text-sm">&copy; {new Date().getFullYear()} Vlocodeu. All rights reserved.</p>
        
        {/* Social Media Links */}
        <ul className="flex justify-center mt-4 space-x-6">
          <li>
            <a href="https://www.instagram.com/vlocodeu" target="_blank" rel="noopener noreferrer" className="hover:text-teal-500">
              <FaInstagram size={24} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/vlocodeu" target="_blank" rel="noopener noreferrer" className="hover:text-teal-500">
              <FaTwitter size={24} />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/vlocodeu" target="_blank" rel="noopener noreferrer" className="hover:text-teal-500">
              <FaYoutube size={24} />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/vlocodeu" target="_blank" rel="noopener noreferrer" className="hover:text-teal-500">
              <FaLinkedin size={24} />
            </a>
          </li>
        </ul>

        {/* Modals for Privacy Policy & Terms */}
        <ul className="flex justify-center mt-4 space-x-4">
          <li>
            <PrivacyPolicyModal /> {/* Insert the modal trigger here */}
          </li>
          <li>
            <TermsAndConditionsModal />
          </li>
          <li>
            <a href="#contact" className="hover:underline">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
