import { motion } from 'framer-motion';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import python from '../assets/python.png';  // Import your images
import laravel from '../assets/laravel.png';
import reactImg from '../assets/react.png';
import angular from '../assets/angular.svg';
import flutter from '../assets/flutter.png';  // Add mobile dev images
import reactNative from '../assets/reactNative.png';
import xamarin from '../assets/xamarin.png';
import seo from '../assets/seo.png';         // Add digital marketing images
import ppc from '../assets/ppc.png';
import content from '../assets/content.png';
import tensorflow from '../assets/tensorflow.png';  // Add AI images
import pytorch from '../assets/pytorch.png';
import keras from '../assets/keras.png';
import php from '../assets/php.png';

const Services = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="min-h-screen py-16 bg-transparent"
    >
      <div className="mt-10 container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-white gradient-text">Our Services</h2>
        <p className="text-lg mt-4 text-center text-white">
          We specialize in web development, mobile apps, and AI solutions.
        </p>

        {/* Services Section */}
        <motion.div className="grid grid-cols-1 sm:grid-cols-2 gap-8 p-8 shadow-lg rounded-lg mt-12">
          <ServiceCard
            title="Web Application Development"
            description="(Python, Laravel, React, Angular)"
            images={[
              { src: python, name: "Python", function: "Backend Development" },
              { src: laravel, name: "Laravel", function: "Web Framework" },
              { src: reactImg, name: "React", function: "Frontend Framework" },
              { src: angular, name: "Angular", function: "Single Page Applications" },
              { src: php, name: "PHP", function: "Backend Development" },
              
            ]}
          />
          <ServiceCard
            title="Mobile App Development"
            description="(Flutter, React Native, Xamarin)"
            images={[
              { src: flutter, name: "Flutter", function: "Cross-platform Mobile Apps" },
              { src: reactNative, name: "React Native", function: "Mobile Apps Development" },
              { src: xamarin, name: "Xamarin", function: "Native Mobile Development" },
            ]}
          />
          <ServiceCard
            title="Digital Marketing"
            description="(SEO, PPC, Content Strategy)"
            images={[
              { src: seo, name: "SEO", function: "Search Engine Optimization" },
              { src: ppc, name: "PPC", function: "Pay-Per-Click Advertising" },
              { src: content, name: "Content Strategy", function: "Content Development" },
            ]}
          />
          <ServiceCard
            title="Artificial Intelligence"
            description="(TensorFlow, PyTorch, Keras)"
            images={[
              { src: tensorflow, name: "TensorFlow", function: "AI and Machine Learning" },
              { src: pytorch, name: "PyTorch", function: "Deep Learning Framework" },
              { src: keras, name: "Keras", function: "Neural Networks" },
            ]}
          />
        </motion.div>
      </div>
    </motion.section>
  );
};

// ServiceCard component with a grid of images for each service
const ServiceCard = ({ title, description, images }) => {
  const [isPaused, setIsPaused] = useState(false); // State to control the marquee animation

  return (
    <motion.div className="bg-white bg-opacity-10 backdrop-blur-sm p-6 shadow-lg rounded-lg text-center transition-all duration-300 relative">
      <h3 className="text-2xl font-semibold text-white">{title}</h3>
      <p className="text-lg mt-4 text-white">{description}</p>
      
      {/* Grid for images with marquee effect */}
      {images && (
        <div className="marquee-container overflow-hidden mt-4">
          <motion.div
            className="marquee flex"
            // Control animation play state based on hover
            style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
          >
            {images.map((imgObj, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg transition-transform duration-300 cursor-pointer mx-2"
                onHoverStart={() => setIsPaused(true)}  // Pause on hover
                onHoverEnd={() => setIsPaused(false)}   // Resume when hover ends
              >
                <img
                  src={imgObj.src}
                  alt={imgObj.name}
                  className="fixed-size-image"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                  <h4 className="text-lg font-semibold text-white">{imgObj.name}</h4>
                  <p className="text-sm text-white">{imgObj.function}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      )}
    </motion.div>
  );
};

export default Services;
