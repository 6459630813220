import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const AboutUs = () => {
  // Animation settings
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  return (
    <section className="min-h-screen py-16">
      <div className="container mx-auto px-6">

        {/* Introduction Section */}
        <motion.div
          className="mt-10 mb-12 bg-white bg-opacity-10 backdrop-blur-lg p-8 shadow-lg rounded-lg"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <h1 className="text-4xl font-bold text-white text-center gradient-text">About Us</h1>
          <p className="text-lg mt-4 text-white text-center">
          Crafting Digital Solutions, Empowering Businesses
          </p>
          <p className="text-lg mt-4 text-white ">
          Founded in 2020, VLOCODEU is a dynamic software company 
committed to delivering innovative and tailored solutions that drive digital 
transformation. With a passion for technology and a focus on client success, 
we've established ourselves as a trusted partner for businesses of all sizes. Our Mission To empower businesses through cutting-edge technology, 
providing exceptional software solutions that streamline operations, enhance 
efficiency, and drive growth. Our Vision To be a leading provider of innovative software solutions, 
          recognized for our expertise, reliability, and customer satisfaction.
          </p>
        </motion.div>

        {/* Main Content with Individual Cards */}
        <div className="space-y-8">
          {/* Card 1: Company Overview */}
          <AnimatedCard title="What We Offer">
            <dl>
              <li classname="mb-4 ">Custom Software Development: Tailored solutions to meet your specific 
              business needs.</li>
              <li classname="mb-4 ">Web Development: Creating stunning and functional websites that 
              captivate your audience.</li>
              <li classname="mb-4 ">Mobile App Development: Developing user-friendly mobile apps for 
              seamless experiences. </li>
              <li classname="mb-4 ">IT Consulting: Expert advice and guidance to optimize your technology 
              infrastructure.</li>
            </dl>
          </AnimatedCard>

          {/* Card 2: Mission */}
          <AnimatedCard title="Why Choose VLOCODEU?">
            <dl>
              <li>Experienced Team: Our skilled team of developers and consultants 
              brings years of industry experience.</li>
              <li>Client-Centric Approach: We prioritize your needs and work closely 
              with you to achieve your goals.</li>
              <li>Innovative Solutions: We embrace emerging technologies to deliver 
              cutting-edge solutions.</li>
              <li>Quality Assurance: We adhere to rigorous quality standards to ensure 
              exceptional results.</li>
              <li>Strong Partnerships: We collaborate with trusted partners to provide 
              comprehensive services.</li>
            </dl>
          </AnimatedCard>

          {/* Card 6: Join Us */}
          <AnimatedCard title="Join Us">
            Join us on our journey to shape the future of technology. Let's work together to elevate your business.
          </AnimatedCard>
        </div>
      </div>
    </section>
  );
};

// Reusable AnimatedCard component
const AnimatedCard = ({ title, children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // Trigger animation once when in view

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  return (
    <motion.div
      ref={ref}
      className="p-8 bg-white bg-opacity-10 backdrop-blur-lg shadow-lg rounded-lg"
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      variants={fadeInUp}
    >
      <h3 className="text-2xl font-semibold text-white">{title}</h3>
      <p className="mt-4 text-lg text-white">{children}</p>
    </motion.div>
  );
};

export default AboutUs;
