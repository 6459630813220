import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import HeroSection from './components/HeroSection';  // Correctly import HeroSection
import AboutUs from './components/AboutUs';          // Correctly import AboutUs
import Services from './components/Services';        // Correctly import Services
// import Portfolio from './components/Portfolio';      // Correctly import Portfolio
import Contact from './components/Contact';   
// import Testimonials from './components/Testimonials';  // Correctly import Testimonials
import Blog from './components/Blog';
import Header from './components/Header';  // Correctly import Header
import Footer from './components/Footer';  // Correctly import Footer

function App() {
  const [activeSection, setActiveSection] = useState("hero");

  const sectionsRef = useRef({
    hero: null,
    about: null,
    services: null,
    portfolio: null,
    contact: null,
    testimonials: null,
    blog: null,
  });

  // Function to scroll to the About Us section
  const scrollToAboutUs = () => {
    if (sectionsRef.current.about) {
      sectionsRef.current.about.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    Object.values(sectionsRef.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, [setActiveSection]);

  return (
    <Router>
      {/* Header is visible on all routes */}
      <Header activeSection={activeSection} setActiveSection={setActiveSection} />
      
      <div>
        {/* Sections of the homepage */}
        <section id="hero" ref={(el) => (sectionsRef.current.hero = el)} style={{ paddingTop: '60px' }}>
          <HeroSection scrollToAboutUs={scrollToAboutUs} /> {/* Pass the scrollToAboutUs function */}
        </section>
        <section id="about" ref={(el) => (sectionsRef.current.about = el)} style={{ paddingTop: '60px' }}>
          <AboutUs />
        </section>
        <section id="services" ref={(el) => (sectionsRef.current.services = el)} style={{ paddingTop: '60px' }}>
          <Services />
        </section>
       {/*
        <section id="portfolio" ref={(el) => (sectionsRef.current.portfolio = el)} style={{ paddingTop: '60px' }}>
          <Portfolio />
        </section>
        <section id="testimonials" ref={(el) => (sectionsRef.current.testimonials = el)} style={{ paddingTop: '60px' }}>
          <Testimonials />
        </section>
        */}
        <section id="blog" ref={(el) => (sectionsRef.current.blog = el)} style={{ paddingTop: '100px' }}>
          <Blog />
        </section>
        <section id="contact" ref={(el) => (sectionsRef.current.contact = el)} style={{ paddingTop: '60px' }}>
          <Contact />
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </Router>
  );
}

export default App;
