import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import React from "react";
import "../styles.css";
import Swal from "sweetalert2";
import email from "../assets/email.png";
import phone from "../assets/phone.png";

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "ad862fba-9628-418c-83b1-26cc2d879718");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      Swal.fire({
        title: "Success!",
        text: "Message send successfully",
        icon: "success",
      });
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  const { ref, inView } = useInView({ threshold: 0.1 });
  const [isHuman, setIsHuman] = useState(false);

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="min-h-screen py-16 bg-transparent"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center gradient-text">
          Contact Us
        </h2>
        <p className="text-lg mt-4 text-center text-white">
          We would love to hear from you! Please fill out the form or use the
          information below.
        </p>

        {/* Grid Layout */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Side: Contact Info */}
          <div className="flex flex-col justify-center text-white">
            <div className="flex items-center mb-4">
              <img src={email} alt="Email" className="w-6 h-6 mr-2" />
              <a
                href="mailto:ourvlocodeu@gmail.com"
                className="text-lg hover:underline"
              >
                ourvlocodeu@gmail.com
              </a>
            </div>

            <div className="flex items-center mb-4">
              <img src={phone} alt="Phone" className="w-6 h-6 mr-2" />
              <a href="tel:+919597754524" className="text-lg hover:underline">
                +91 9597754524
              </a>
            </div>

            <div className="flex items-center mb-4">
              <img src={phone} alt="Phone" className="w-6 h-6 mr-2" />
              <a href="tel:+910000000000" className="text-lg hover:underline">
                +91 0000000000
              </a>
            </div>

            {/* Business Hours with hover effect */}
            <div className="relative flex items-center mb-4">
              <span className="text-lg text-white hover:underline cursor-pointer">
                Business Hours :
              </span>
              <div
                className="absolute bg-white text-black text-sm rounded-lg shadow-lg p-2 opacity-0 transition-opacity duration-300"
                style={{
                  left: "150px", // Adjust this to control the position of the tooltip on the right
                  whiteSpace: "nowrap",
                  zIndex: "10",
                }}
                id="business-hours-tooltip"
              >
                Monday to Friday (9 AM to 6 PM) IST
              </div>
            </div>

            {/* Google Maps */}
            <div className="mb-4">
              <iframe
                title="Vlocodeu" // Add this title attribute
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7839.04059601299!2d78.68066592866263!3d10.771405097093798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baa8b60e3c4e74f%3A0x2d29238952fc068e!2sMNR%20Agrotech%20Oil%20mill!5e0!3m2!1sen!2sus!4v1730779407743!5m2!1sen!2sus"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

              <div className="text-lg text-white mt-2">
                <a
                  href="https://maps.app.goo.gl/DbvVBLcqhSFfTueD6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Get Directions
                </a>
              </div>
            </div>
          </div>

          {/* Right Side: Form */}
          <form
            onSubmit={onSubmit}
            className="bg-white shadow-md rounded-lg p-6"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
              backdropFilter: "blur(10px)", // Apply blur effect
              WebkitBackdropFilter: "blur(10px)", // For Safari support
            }}
          >
            {/* Name */}
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2 text-white"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  backdropFilter: "blur(5px)",
                }}
                required
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2 text-white"
                htmlFor="email"
              >
                Email ID
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  backdropFilter: "blur(5px)",
                }}
                required
              />
            </div>

            {/* Message */}
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2 text-white"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                placeholder="Your Message"
                name="message"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                rows="4"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  backdropFilter: "blur(10px)",
                }}
                required
              ></textarea>
            </div>

            {/* CAPTCHA-like Checkbox */}
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="captcha"
                className="mr-2"
                checked={isHuman}
                onChange={() => setIsHuman(!isHuman)}
              />
              <label
                className="block text-sm font-bold text-white"
                htmlFor="captcha"
              >
                I'm not a robot
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white rounded-lg py-2 mt-4 hover:bg-blue-500 transition"
              disabled={!isHuman} // Disable button if CAPTCHA is not checked
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;
