import React, { useState } from 'react';
import Modal from 'react-modal';

// Custom styles for the modal content and background blur
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency for the modal background
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark semi-transparent overlay
    backdropFilter: 'blur(8px)', // Apply the blur effect here
  },
};

Modal.setAppElement('#root');

const PrivacyPolicyModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <button
        className="hover:underline text-sm"
        onClick={openModal}
      >
        Privacy Policy
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Privacy Policy Modal"
      >
        <div className="p-6">
          <h2 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h2>
          <p className='text-center'>
            VLOCODEU website is owned by VLOCODEU, which is a data controller of your personal data. 
            </p>
            <p className='text-center mt-2 mb-2'>We have adopted this Privacy Policy, which determines how we are processing the information collected by VLOCODEU, which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using the VLOCODEU website.</p> 
            <p className='text-center'>We take care of your personal data and undertake to guarantee its confidentiality and security.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Personal Information We Collect:</h3>
          <p>
            When you visit VLOCODEU, we automatically collect certain information about your device, including your web browser, IP address, time zone, and installed cookies. Additionally, we collect information on web pages or products you view, how you interact with the Site, and websites or search terms that referred you. This is referred to as "Device Information." We may also collect personal data you provide to us (e.g., Name, Address, Payment Info) during registration to fulfill the agreement.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Why Do We Process Your Data?</h3>
          <p className='mb-2'>
          Our top priority is customer data security, and, as such, we may process only minimal user data, only 
as much as it is absolutely necessary to maintain the website. Information collected automatically is 
used only to identify potential cases of abuse and establish statistical information regarding website 
usage. This statistical information is not otherwise aggregated in such a way that it would identify 
any particular user of the system.
          </p>
          <p>
          You can visit the website without telling us who you are or revealing any information, by which 
someone could identify you as a specific, identifiable individual. If, however, you wish to use some of 
the website’s features, or you wish to receive our newsletter or provide other details by filling a form, 
you may provide personal data to us, such as your email, first name, last name, city of residence, 
organization, telephone number. You can choose not to provide us with your personal data, but then 
you may not be able to take advantage of some of the website’s features. For example, you won’t be 
able to receive our Newsletter or contact us directly from the website. Users who are uncertain 
about what information is mandatory are welcome to contact us via <a href="mailto:ourvlocodeu@gmail.com" className="text-blue-500 underline"> ourvlocodeu@gmail.com</a>.
          </p>
          

          <h3 className="text-xl font-semibold mt-6 mb-2">Your Rights:</h3>
          <ul className="list-disc ml-6">
            <li>The right to be informed.</li>
            <li>The right of access.</li>
            <li>The right to rectification.</li>
            <li>The right to erasure.</li>
            <li>The right to restrict processing.</li>
            <li>The right to data portability.</li>
            <li>The right to object.</li>
            <li>Rights in relation to automated decision-making and profiling.</li>
          </ul>
          <p className='mb-2'>
          If you would like to exercise this right, please contact us through the contact information below.
          </p>
          <p>
          Additionally, if you are a European resident, we note that we are processing your information in 
order to fulfill contracts we might have with you (for example, if you make an order through the Site), 
or otherwise to pursue our legitimate business interests listed above. Additionally, please note that 
your information might be transferred outside of Europe, including Canada and the United States.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Links to Other Websites:</h3>
          <p>
          Our website may contain links to other websites that are not owned or controlled by us. Please be 
aware that we are not responsible for such other websites or third parties' privacy practices. We 
encourage you to be aware when you leave our website and read the privacy statements of each 
website that may collect personal information.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Information Security:</h3>
          <p>
          We secure information you provide on computer servers in a controlled, secure environment, 
protected from unauthorized access, use, or disclosure. We keep reasonable administrative, 
technical, and physical safeguards to protect against unauthorized access, use, modification, and 
personal data disclosure in its control and custody. However, no data transmission over the Internet 
or wireless network can be guaranteed.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Legal Disclosure:</h3>
          <p>
          We will disclose any information we collect, use or receive if required or permitted by law, such as to 
comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is 
necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or 
respond to a government request.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-2">Contact Information:</h3>
          <p>
          If you would like to contact us to understand more about this Policy or wish to contact us concerning 
any matter relating to individual rights and your Personal Information, you may send an email to <a href="mailto:ourvlocodeu@gmail.com" className="text-blue-500 underline">ourvlocodeu@gmail.com</a>.
          </p>

          <button
            onClick={closeModal}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
