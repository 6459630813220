import React from "react";
import { Link } from "react-scroll"; // For smooth scroll navigation
import "../styles.css";
import logo from "../assets/logo.png";

const Header = ({ activeSection, setActiveSection }) => {
  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
    <header
      className="mt-4 mb-10 backdrop-blur-md bg-white/10 rounded-full mx-4 py-3 sm:mx-8 sm:py-3 fixed top-0 left-0 right-0 z-50"
      style={{
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        height: "60px",
      }}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-bold text-white">
          <img
            src={logo}
            alt="Vlocodeu Logo"
            className="sm:h-10 ml-5 pb-2.5"
          />
        </div>

        {/* Navigation */}
        <nav className="hidden md:flex flex-grow justify-center space-x-4 sm:space-x-6">
          <ul className="flex justify-center space-x-4 sm:space-x-6">
            <li>
              <Link
                to="hero"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("hero")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "hero" ? "neon-text" : "default-text"}`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("about")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "about" ? "neon-text" : "default-text"}`}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="services"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("services")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "services" ? "neon-text" : "default-text"}`}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="portfolio"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("portfolio")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "portfolio" ? "neon-text" : "default-text"}`}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("testimonials")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "testimonials" ? "neon-text" : "default-text"}`}
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                to="blog"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("blog")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "blog" ? "neon-text" : "default-text"}`}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth={true}
                duration={700}
                onClick={() => handleSetActive("contact")}
                className={`py-2 px-4 rounded-full transition cursor-pointer ${activeSection === "contact" ? "neon-text" : "default-text"}`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
